<template>
  <v-container
    fluid
    :class="
      screenSize === 'Large' ? 'crash-page-cont mt-n1' : 'small-crash-page-cont'
    "
  >
    <v-row
      :class="screenSize === 'Large' ? 'ml-0 fixed-top-row' : ' '"
      flat
      no-gutters
    >
      <v-col cols="4" class="mt-2 mb-1" @click="redirect('LandingPage')">
        <v-img :src="require('@/assets/newlogo.png')" class="logo-img ml-2" />
      </v-col>

    
      <v-col class="mt-2 mb-1" cols="8" align="left">
        <v-row
          ><v-col align="right" class="" v-if="isLoggedIn">
            <span class="bal-text" :class="screenSize === 'Large' ? 'black-white' : 'text-white'">
              {{
                isValidNumber(BalanceCrash) && BalanceCrash !== 0
                  ? formatNumberWithCommas(BalanceCrash)
                  : isValidNumber(User.balance)
                  ? formatNumberWithCommas(User.balance)
                  : ""
              }}
              KSH
            </span></v-col
          >

          
          <v-btn
            v-if="isLoggedIn && this.$route.name.includes('profilePage') || this.$route.name.includes('Promotion') ||this.$route.name.includes('LandingPage') || this.$route.name.includes('withdraw')"
            variant="flat"
            class="pep-login-btn text-none mr-8 mt-2"
            size="small"
            :to="{ name: 'depositMoney' }"
            >Deposit</v-btn
          >

          <!-- <v-btn
            v-if="isLoggedIn && this.$route.name.includes('withdraw') "
            variant="flat"
            class="pep-login-btn text-none mr-8 mt-2"
            size="small"
            :to="{ name: 'depositMoney' }"
            >Deposit</v-btn
          > -->

          <v-btn
            v-if="isLoggedIn  && this.$route.name.includes('depositMoney')"
            variant="flat"
            class="pep-login-btn text-none mr-2 mt-2"
            size="small"
            :to="{ name: 'withdraw' }"
            >Withdraw</v-btn
          >
        </v-row>

        <v-row v-if="!isLoggedIn" >
          <v-col align="right" class="mx-4">
             <v-btn
              flat
              class="pep-login-btn text-none mt-n1 mr-2"
              variant="outlined"
              :to="{ name: 'signIn' }"
              >Login</v-btn
            > 
            <v-btn
              flat
              class="pep-reg-btn text-none mt-n1"
              :to="{ name: 'signUp' }"
              >Register</v-btn
            ></v-col
          >
        </v-row>
      </v-col>
    </v-row>
    <!-- <v-divider class="mt-0" /> -->

    <v-main >
      <transition name="slide-fade">
        <div v-if="loaded" class="page">
          <router-view></router-view>
        </div>
      </transition>

      <div class="center" v-if="!loaded">
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
      </div>
    </v-main>
    <!-- </v-app> -->
    <footerPage />
  </v-container>
</template>

<script>
import footerPage from "@/modules/Main/components/footer.vue";
import { AuthService } from "@/modules/auth";
import io from "socket.io-client";


export default {
  name: "homePage",

  components: { footerPage },

  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (AuthService.check()) {
        v.$store.dispatch("auth/getUser", {
          phone: JSON.parse(AuthService.user).phone,
        });
      }
    });
  },

  data: () => ({
    loaded: false,
    BalanceCrash: 0,
    test: false,

    screenSize: "",
    open: ["Admin"],
    admins: [
      ["My Bets", "mdi-file-document"],
      ["Boosted Odds", "mdi-cog-outline"],
      ["Player to Score", "mdi-cog-outline"],
      ["Sports", "mdi-cog-outline"],
      ["Live Games", "mdi-cog-outline"],
    ],

    drawer: false,
    rail: false,
    dialog: false,
    activate: false,
  }),

  computed: {
    isLoggedIn() {
      return AuthService.check();
    },
    userDetails() {
      return JSON.parse(AuthService.user);
    },
    User() {
      return this.$store.getters["auth/authGetters"]("userD");
    },
    alertMessage() {
      return this.$store.getters["auth/authGetters"]("alert");
    },
  },
  mounted() {
    const socket = io("https://api.tililtechnologies.com:3000", {
      transports: ["websocket"],
      extraHeaders: {
        "Access-Control-Allow-Origin": "*",
        // Add any other headers you need here
      },
    });

    if (AuthService.check()) {
      const ID = JSON.parse(AuthService.user).user_id;
      socket.on(`${ID}_limbo_bet`, (data) => {
        this.BalanceCrash = data.updatedBalance;
      });
    }

    setTimeout(() => {
      this.loaded = true;
    }, 10);

    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
  },
  methods: {
    toggleSpin() {
      this.isSpinning = !this.isSpinning;
    },
    selectLink(link) {
      this.selectedLink = link;
    },
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 767px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },

    formatNumberWithCommas(number) {
      const val = Number(number);
      return val.toLocaleString();
    },

    redirect(val) {
      this.$router.push({ name: val });
    },
    isValidNumber(value) {
      return typeof value === "number" && !isNaN(value);
    },
  },

  watch: {
    // $route(to, from) {
    // this.loaded = false;
    // Set loaded back to true after 2 seconds (adjust as needed)
    // setTimeout(() => {
    //   this.loaded = true;
    // }, 10);
    // console.log("Route changed from", from.fullPath, "to", to.fullPath);
    // },
  },
};
</script>

<style scoped>
@import url("../style.css");
</style>
